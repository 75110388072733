@import '../../_mixin';
@import '../../_variables';

.newsCard {
  background-color: #f4f4f4;
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &__image {
    width: 100%;
    height: 0;
    padding-bottom: 60%;
    background-position: center;
    background-size: cover;
  }

  .newsCard__body {
    display: flex;
    padding: 1rem 0.5rem;
    flex-direction: column;

    .newsCard__date {
      color: $primary;
    }

    .newsCard__title {
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 1rem;
      margin-top: 0.25rem;
    }
  }
}

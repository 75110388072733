$breakpoint-xs: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1440px;

$container-width: 1280px;

$black: #000000;
$black-5: #f7f7f7;
$black-10: #e6e6e6;
$black-20: #cccccc;
$black-30: #b3b3b3;
$black-40: #999999;
$black-50: #808080;
$black-60: #666666;
$black-70: #4d4d4d;
$black-80: #333333;
$black-90: #1a1a1a;

$primary: #00bf63;

@import '../../_mixin';
@import '../../_variables';

.hero {
  position: relative;
  padding: 2rem 0;

  @include sm {
    padding: 4rem 0;
  }

  .hero__image {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: $black;
      opacity: 0.75;
    }
  }

  &__container {
    @include container;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    .hero__title {
      font-size: 2rem;
      max-width: 60rem;
      font-weight: 800;
      color: white;
      text-align: center;

      @include sm {
        font-size: 3rem;
      }
    }

    .hero__text {
      color: white;
      text-align: center;
      opacity: 0.8;
      max-width: 60rem;
    }
  }
}

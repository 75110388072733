@import '../../../../_mixin';
@import '../../../../_variables';

.header {
  border-bottom: 1px solid $black-10;
  padding: 1rem 0;

  &__container {
    @include container;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .header__logo {
      height: 2rem;
      cursor: pointer;

      @include sm {
        height: 4rem;
      }
    }

    .header__body {
      display: none;
      @include sm {
        display: flex;
      }
      flex-direction: row;
      gap: 1.5rem;
      label {
        cursor: pointer;
      }
    }

    .header__mobile {
      @include sm {
        display: none;
      }
    }
  }
}

@import '../../_mixin';

.tasks {
  padding: 2rem 0;

  .tasks__container {
    @include container;
    padding: 2rem 0;

    .tasks__text {
      font-weight: 700;
    }

    .tasks__list {
      padding-left: 2rem;
      padding-top: 0.5rem;
    }
  }
}

@import '../../../_mixin';
@import '../../../_variables';

.sectionTitle {
  @include container;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  .title {
    font-size: 0.75rem;

    font-weight: 900;
    color: $primary;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    text-transform: uppercase;

    @include sm {
      font-size: 1.25rem;
    }

    &::after,
    &::before {
      content: '';
      height: 3px;
      width: 3rem;
      background-color: $primary;
    }
  }

  .subtitle {
    font-weight: 900;
    font-size: 1.25rem;
    max-width: 50rem;
    text-align: center;

    @include sm {
      font-size: 1.75rem;
    }
  }
}

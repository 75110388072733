@import '../../_mixin';
@import '../../_variables';

.news {
  padding: 2rem 0;

  &__container {
    @include container;
    padding: 2rem 0;
    gap: 2rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    @include sm {
      grid-template-columns: repeat(2, 1fr);
    }

    @include lg {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

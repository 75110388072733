@import '../../_mixin';
@import '../../_variables';

.administration {
  padding: 2rem 0;
  background-color: $black-5;

  &__container {
    @include container;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}

@import '../../_mixin';
@import '../../_variables';

.resolutions {
  padding: 2rem 0;

  &__container {
    @include container;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    p {
      text-indent: 2rem;
    }
  }
}

@import '../../_mixin';
@import '../../_mixin';
@import '../../_variables';

.about {
  padding: 2rem 0;

  .about__container {
    @include container;
    padding: 2rem 0;
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(1, 1fr);

    @include md {
      grid-template-columns: repeat(2, 1fr);
    }

    .about__body {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .about__text {
        text-indent: 2rem;
      }
    }
    .about__image {
      height: 0;
      padding-bottom: 60%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}

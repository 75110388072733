@import '../../_mixin';
@import '../../_variables';

.order {
  padding: 2rem 0;
  background-color: $black-5;

  .order__container {
    @include container;
    padding: 2rem 0;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    p {
      text-indent: 2rem;
    }
  }
}

@import '../../_mixin';
@import '../../_variables';

.sign {
  padding: 2rem 0;

  .sign__container {
    @include container;
    padding: 2rem 0;

    .form {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .form__row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
      }

      .form__cb {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        font-weight: 600;
        margin-top: 0.5rem;

        label {
          font-size: 1.25rem;
        }
      }

      .form__button {
        margin-top: 1rem;
      }
    }
  }
}

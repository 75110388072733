@import '../../../../../../_mixin';
@import '../../../../../../_variables';

.mobile__icon {
  font-size: 2rem;
  cursor: pointer;
}

.mobile__nav {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  width: 80%;
  max-width: 20rem;
  transform: translateX(100%);
  pointer-events: none;
  z-index: 100;
  display: flex;
  flex-direction: column;
  transition: all 0.25s ease-out;

  label {
    padding: 1rem;
    cursor: pointer;
    font-weight: 700;
    transition: all 0.25s ease-out;

    &:hover {
      color: $primary;
    }

    &:active {
      opacity: 0.1;
    }
  }

  &.active {
    transform: translateX(0);
    pointer-events: all;
  }
}

.mobile__overlay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: black;
  opacity: 0.5;
  z-index: 95;
}

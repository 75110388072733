@import '../../_mixin';
@import '../../_variables';

.contact {
  padding: 2rem 0;
  background-color: $black-5;

  &__container {
    @include container;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .contact__form {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .contact__button {
        margin-top: 1rem;
        width: 100%;
      }
    }

    .container__header {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 1rem;

      @include sm {
        grid-template-columns: repeat(2, 1fr);
      }

      a {
        text-decoration: none;
        color: $black;
      }

      .container__item {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;
        padding: 1rem 1.5rem;
        border-radius: 0.5rem;
        background-color: white;
        cursor: pointer;
        font-weight: 600;

        & * {
          cursor: pointer;
        }

        .item__icon {
          font-size: 2rem;
          transform: translateY(10%);
          color: $primary;
        }

        .item__col {
          display: flex;
          flex-direction: column;
          gap: 0.25rem;
        }
      }
    }
  }
}

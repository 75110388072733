@import '../../_mixin';
@import '../../_variables';

.purpose {
  padding: 2rem 0;
  background-color: $black-5;

  .purpose__container {
    @include container;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .container__text {
      text-indent: 2rem;
    }

    .container__list {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      text-indent: 1rem;
      font-weight: 700;
      font-size: 1.125rem;
    }
  }
}

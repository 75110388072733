@import '../../../_mixin';
@import '../../../_variables';

.formInput {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 2rem;

  .formInput__title {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .formInput__input {
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    background-color: $black-5;
    border: 1px solid $black-40;
    color: $black-90;
    font-size: 1rem;

    @include sm {
      padding: 1rem 1.25rem;
    }
  }
}

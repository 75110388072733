@import '../../_mixin';
@import '../../_variables';

.generalMeeting {
  padding: 2rem 0;
  background-color: $black-5;

  &__container {
    @include container;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .container__item {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .item__title {
        font-size: 1.5rem;
        font-weight: 800;

        @include sm {
          font-size: 2rem;
        }
      }
    }
  }
}

@import '../../../../_mixin';
@import '../../../../_variables';

.footer {
  padding: 1rem 0;
  background-color: $black-10;
  .footer__container {
    @include container;
  }
}

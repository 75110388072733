@import '../../../_mixin';
@import '../../../_variables';

.button {
  font-size: 1.25rem;
  width: 100%;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  color: white;
  background-color: $primary;
  border: 2px solid $primary;
  transition: all 0.25s ease-out;

  &:hover {
    background-color: transparent;
    color: $primary;
  }
}

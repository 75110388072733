@import '../../_mixin';
@import '../../_variables';

.aboutMembers {
  padding: 2rem 0;

  .aboutMembers__container {
    @include container;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .container__item {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .item__title {
        font-size: 1.5rem;
        font-weight: 800;

        @include sm {
          font-size: 2rem;
        }
      }

      .item__body {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
    }
  }
}
